<template>
    <div class="home">
        <Header :isHome="true" />

        <div class="home-con">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item,n) in list" :key="n">
                    <p class="p1">{{item.p1}}</p>
                    <p class="p2">{{item.p2}}</p>
                </van-swipe-item>
            </van-swipe>
            <van-button class="startStudy" type="primary" @click="startStudy">开始学习</van-button>
        </div>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
    name: 'Home',
    data() {
        return {
            list: [
                {
                    p1:'直面考纲，一题千变',
                    p2:'学习需行之有效',
                },
                {
                    p1:'科学刷题，溯本追源',
                    p2:'见题如胸有成竹',
                },
                {
                    p1:'学贵以专，蜂算引之',
                    p2:'数学当脉络分明',
                }
            ]
        }
    },
    components: {
        Header,
    },
    methods: {
        startStudy() {
            this.$router.push({path:'/main'})
            // if(localStorage.access_token){
            // }else{
            //     this.$router.push({path:'/login'})
            // }
            
        }
    },
    created () {
        this.$store.commit('setHashName','Home');
    },
    destroyed () {
        // this.$store.commit('setHashName','');;
    },
}
</script>

<style lang="less" scoped>
    .home{
        width: 100%;
        height: 100vh;
        background: url('./../assets/img/bg.jpg') center;
        background-size: cover;
        &-con{
            position: relative;
            .my-swipe{
                padding-top: 100px;
                box-sizing: border-box;
                height: 85vh;
                .p1{
                    text-align: center;
                    height: 27px;
                    font-size: 18px;
                    font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                    font-weight: bold;
                    color: #F9C200;
                    line-height: 27px;
                }
                .p2{
                    text-align: center;
                    height: 59px;
                    font-size: 40px;
                    font-family: SourceHanSans-Medium, SourceHanSans;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 59px;
                    letter-spacing: 1px;
                }
            }
            .startStudy{
                position: absolute;
                top: 238px;
                left: 50%;
                margin-left: -2.34rem;
                width: 150px;
            }
        }
    }
</style>
