/*
 * @Author: jhy
 * @Date: 2022-08-21 09:57:41
 * @LastEditTime: 2023-05-22 22:23:21
 * @LastEditors: jhy
 * @Description: 
 * @FilePath: /myProject/math-app/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import VueClipboard from 'vue-clipboard2'
// import 'vant/lib/index.css';
// 引入全部样式
import 'vant/lib/index.less';

// 引入单个组件样式
// import '@/assets/css/var.less';

import kx from "katex";
import Meta from 'vue-meta';
import "katex/dist/katex.min.css";

import api  from '@/api/index' // 导入api接口

import '@/assets/css/index.less'

import moment from "moment";

import propPay from '@/components/pay.vue';

Vue.use(Vant);

window.$router = router;

Vue.use(api,{router,store}) // api插件&全局路由钩子方法
Vue.use(VueClipboard)  // 复制到剪切板
Vue.use(Meta)
Vue.config.productionTip = false

Vue.prototype.$kx = kx;
Vue.prototype.$moment = moment;
Vue.prototype.$propPay = propPay;

router.beforeEach((to, from, next) => {
  console.log('to',to);
  store.commit('setHashName',to.name);
  next()
})

// 基准大小
const baseSize = 32
// 设置 rem 函数
function setRem() {
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 375
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

;(function(){
  var rem = document.createElement('script');
  rem.src = './rem.js';
  document.body.appendChild(rem)
})()
