import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/index.vue'),
        meta:{
            title:'login'
        },
    },
    {
        path: '/token',
        name: 'token',
        component: () => import('../pages/activation')
        // component: () => import('../views/token.vue'),
    },
    {
        path: '/yindao',
        name: 'yindao',
        component: () => import('../views/yindao.vue')
    },
    {
        path: '/',
        name: 'main',
        component: () => import('../pages/home/')
        // component: () => import('../views/main/index.vue')
    },
    {
        path: '/main',
        name: 'main',
        component: () => import('../pages/home/')
        // component: () => import('../views/main/index.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta:{
            title:'login'
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
    },
    {
        path: '/main',
        name: 'main',
        component: () => import('../views/main/index.vue')
    },
    // {   // 专题页
    //     path: '/special',
    //     name: 'special',
    //     component: () => import('../views/special/index_1.vue'),
    // },
    {   // 专题页
        path: '/special',
        name: 'special',
        component: () => import('../views/special_1/index.vue'),
    },
    {   // 专题页
        path: '/shuati',
        name: 'shuati',
        component: () => import(/* webpackChunkName: "shuati" */ '../views/special/shuati.vue')
    },
    {   // 专题页 new
        path: '/topic',
        name: 'topic',
        component: () => import('../views/topic/index.vue'),
    },
    {   // relate_exam
        path: '/relate_exam',
        name: 'relate_exam',
        component: () => import('../views/relate_exam/index.vue'),
    },
    {   // zhenti
        path: '/zhenti',
        name: 'zhenti',
        component: () => import('../views/zhenti/index.vue'),
    },
    {   // 专题页 new
        path: '/topic_try',
        name: 'topic_try',
        component: () => import('../views/topic_try/index.vue'),
    },
    {   // 真题
        path: '/zhenti',
        name: 'zhenti',
        component: () => import(/* webpackChunkName: "zhenti" */ '../views/special/zhenti.vue')
    },
    {   // 章节导学
        path: '/guide',
        name: 'guide',
        component: () => import(/* webpackChunkName: "guide" */ '../views/guide/index.vue')
    },
    {   // 学习地图
        path: '/studyMap',
        name: 'studyMap',
        component: () => import(/* webpackChunkName: "studyMap" */ '@/components/studyMap.vue')
    },
    {   // 总结
        path: '/zongjie',
        name: 'zongjie',
        component: () => import('@/views/zongjie.vue')
    },
    {   // 收藏
        path: '/shouCang',
        name: 'shouCang',
        component: () => import('@/views/shouCang.vue')
    },
    {   // test
        path: '/test',
        name: 'test',
        meta:{
            title:'login'
        },
        component: () => import(/* webpackChunkName: "test" */ '../views/test.vue')
    },
    {   // test2
        path: '/test2',
        name: 'test2',
        meta:{
            title:'login'
        },
        component: () => import(/* webpackChunkName: "test2" */ '../views/test2.vue')
    },
    {   // contact
        path: '/contact',
        name: 'contact',
        meta:{
            title:'login'
        },
        component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue')
    },
    {
      path: '/activity',
      name: 'activity',
      meta: {
        title: '活动'
      },
      component: () => import( '../views/activity/index')
    },
    {
      path: '/activity-topic',
      name: 'activity-topic',
      meta: {
        title: '活动题目'
      },
      component: () => import( '../views/activity/topic')
    },
    {
      path: '/activity-topic-list',
      name: 'activity-topic-list',
      meta: {
        title: '开始做题'
      },
      component: () => import( '../views/activity/topicList')
    },
    {
      path: '/activity-result',
      name: 'activity-result',
      meta: {
        title: '结果'
      },
      component: () => import( '../views/activity/result')
    },
    {
      path: '/activity-analyze',
      name: 'activity-analyze',
      meta: {
        title: '结果'
      },
      component: () => import( '../views/activity/analyze')
    },


    {
      path: '/new-home',
      name: 'new-home',
      meta: {
        title: '首页',
      },
      component: () => import('../pages/home/')
    },
    {
      path: '/directory/advance',
      name: 'directory-advance',
      mata: {
        title: '高等数学目录'
      },
      component: () => import ('../pages/directory/AdvanceDirectory')
    },
    {
      path: '/directory/realQuestion',
      name: 'real-question',
      mata: {
        title: '真题精讲精炼（提效版）',
      },
      component: () => import('../pages/directory/RealQuestion')
    },
    {
      path: '/refactor',
      name: 'refactor-question',
      mata: {
        title: '智能组题',
      },
      component: () => import('../pages/refactor')
    },
    {
      path: '/question',
      name: 'do-quesiton',
      mata: {
        title: '题'
      },
      component: () => import('../pages/question')
    },
    {
      path: '/question_1',
      name: 'do-quesiton-1',
      mata: {
        title: '题'
      },
      component: () => import('../pages/question_1')
    },
    {
      path: '/favorites',
      name: 'favorites',
      mata: {
        title: '收藏夹'
      },
      component: () => import('../pages/favorites')
    },
    // {
    //   path: '/token',
    //   name: 'activation',
    //   meta: {
    //     title: '激活'
    //   },
    //   component: () => import('../pages/activation')
    // }

    {
      path: '/new_home',
      name: 'new_home',
      mata: {
        title: '无穹数学辅助'
      },
      component: () => import('../views/new_home/index')
    },
    {
      path: '/new_menu',
      name: 'new_menu',
      mata: {
        title: '快速提高系统'
      },
      component: () => import('../views/new_home/menu')
    },
    {
      path: '/new_topic',
      name: 'new_topic',
      mata: {
        title: '快速提高系统做题'
      },
      component: () => import('../views/new_home/topic')
    },
    {
      path: '/new_result',
      name: 'new_result',
      mata: {
        title: ''
      },
      component: () => import('../views/new_home/result')
    },
    {
      path: '/new_info',
      name: 'new_info',
      mata: {
        title: ''
      },
      component: () => import('../views/new_home/info')
    },
    {
      path: '/new_done',
      name: 'new_done',
      mata: {
        title: ''
      },
      component: () => import('../views/new_home/done')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// router.beforeEach((to, from, next) => {
//     // console.log('to, from',to, from)
//     window.hashName = to.name;
//     if(to.name !== 'login' && !localStorage.access_token){
//         // next({ name: 'login' })
//     } else if(from.name === 'login'){
//         next();
//         location.reload();
//     }
//     else{
//         next()
//     }
// })

export default router
