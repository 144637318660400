import Vue from 'vue'
import Vuex from 'vuex'
import realQuestion from '@/store/realQuestion';

// import train_left from './../mock/train_left';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // navList: train_left.list //''
        navList: {}, // 菜单信息
        topicIsTrue: '',
        hashName: '',
        jinduList: '',
        navListZhenti: '',
        getNavInfo:{},// 当前专题状态
        stepShow: false,//下一步展开
        book:'',
        allZj:{},

        is_registed: false,
        show_pic: false,

        numTopic:0, // 第几题

        bookInfo:{},

        activityMobile: '', // 活动手机号
        activityQuestions: [], // 活动题目列表
        examinationTime: 0, // 答题时间

        headStatus: false,

    },
    mutations: {
        setHeadStatus(state, status){
            console.log('state', state)
            state.headStatus = status;
        },
        // 下一步展开与否
        setStepShow(state,stepShow){
            state.stepShow = stepShow
        },
        // 设置是第几题
        setNumTopic(state,i){
            state.numTopic = i;
        },
        // 专题
        async getNavList (state,name){
            state.book = name;
            await window.$api[name]({},res=>{
                console.log('res', res);
                let list = res.list;
                let numZj = 0;
                list.map(e=>{
                    e.childs.map(e1=>{
                        numZj += e1.childs.length;
                    });
                });
                console.log('numZj', numZj, name);
                state.allZj[name] = numZj;
                // state.allZj = JSON.stringify(state.allZj);
                state.allZj = Object.assign({},state.allZj)


                let navList = {};
                navList = JSON.stringify(navList);
                navList = JSON.parse(navList);
                navList[name] = list;
                navList = JSON.stringify(navList);
                navList = JSON.parse(navList);
                state.navList = navList;
                state.bookInfo[name] = {
                    navList:navList,
                    zhangjie: list.length
                };
                console.log('state.bookInfo', state.bookInfo);
                state.is_registed = res.is_registed;
                state.show_pic = res.show_pic;

            },err=>{
                console.error(err);
            });
        },
        // 真题
        async zhenti_navigate (state){
            if(state.navListZhenti){
                return false;
            }
            await window.$api.zhenti_navigate({},res=>{
                console.log('res', res);
                let list = res.data;
                let array = [];
                list.map(e=>{
                    array.push(...e.childs)
                });
                console.log('array', array);
                state.navListZhenti = [{name:'真题',childs:array}];
            });
        },
        //
        async jindu_query (state){
            if(state.jinduList.length>0){
                return false;
            }
            let param ={
                username: localStorage.username
            };
            await window.$api.jindu_query({param,method:'post'},res=>{
                state.jinduList = res;
            });
        },
        // 题知否作对
        getTopicIsTrue (state,status){
            console.log('statusstatusstatusstatus',status)
            state.topicIsTrue = status;
        },
        // 把该题对错写入后端。后端计算完更新菜单列表接口
        async jindu_write (state,param){
            await window.$api.jindu_write({param,method:'post'},res=>{
                console.log('res', res);
                delete param.obj;
                window.$api.jindu_query({param,method:'post'},res=>{
                    console.log('res', res);
                    let navList = state.special.leftNavInfo;
                    navList.childs.map(e=>{
                        e.childs.map(el=>{
                            if(el.nav_id == res.zhuanti_id){
                                e.stars_count = res.stars;
                            }
                        })
                        return e;
                    })
                });
            });
        },
        // 设置hashName，来判断heder展示
        setHashName(state, hashName){
            state.hashName = hashName;
        },

      // 查询活动题目
      async queryActivityQuestions (state) {
          try {
            const ret = await window.$api.activityQuestions({})
            state.activityQuestions = [...ret]
          } catch (err) {
            console.error(err)
          }
      },

      updateMobile (state, mobile) {
          state.activityMobile = mobile
      },
      setExaminationTime(state, time) {
          state.examinationTime = time
      }
    },
    actions: {},
    modules: {
        realQuestion,
        special:{
            state: () => ({
                showNav: false,
                navInfo: {
                    n:0,
                    childs:[],
                },
                fenbu: false,
                topicData: {}, // 强化题信息
                topicMain: '', // 做题结果 和 html
                html: '',
                topicResult: '',
                leftNavInfo:{},
                this_topic:'',
                topicTotal: 0,
                exam_idx:0,
            }),
            mutations: {
                setExam_idx(state,n){
                    state.exam_idx = n;
                },
                setThis_topic(state, this_topic){ // 当前题名称
                    state.this_topic = this_topic;
                },
                settopicTotal(state, topicTotal){ // 总共题
                    state.topicTotal = topicTotal;
                },
                setNav (state, status){
                    state.showNav = status;
                },
                setleftNavInfo(state, nav){
                    state.leftNavInfo = nav;
                },
                setNavInfo (state, navInfo){ // 设置菜单
                    console.log(navInfo)
                    state.showNav = false;
                    state.navInfo = navInfo;
                    console.log(state)
                },
                setFenbu (state, status) { // 设置分布结果
                    state.fenbu = status;
                },
                setTopicData (state, res) { // 设置取题
                    state.topicData = res;
                },
                setTopicMain (state, param) {  // 设置做题结果
                    state.topicMain = param;
                },
                setTopicMainHtml (state, html) {  // 设置做题结果
                    console.log(html)
                    state.html = html;
                },
                setTopicResult (state, param) {  // 设置做题结果
                    state.topicResult = param;
                },
            },
            actions: {

            }
        }
    }
})
