const realQuestion = {
  namespaced: true,
  state: {
    directory: {},

    curQuestionId: null,
    curQuestionIdx: 0,
  },
  mutations: {
    // 更新目录
    updateDirectory(state, { directory }) {
      state.directory = directory
    },

    updateQuestionInfo (state, { params, chapterIdx, sectionIdx, questionIdx }) {
      const arr = [...state.directory.chapters]

      arr[chapterIdx].sections[sectionIdx].questions[questionIdx] = {
        ...state.directory.chapters[chapterIdx].sections[sectionIdx].questions[questionIdx],
        ...params
      }
      state.directory = {
        ...state.directory,
        chapters: [...arr]
      }
    },

    updateCurQuestion (state, {questionID}) {
      state.curQuestionId = questionID
    }
  },
  actions: {

  }

}

export default realQuestion
