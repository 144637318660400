import axios from 'axios'
import querystring from 'querystring'
import host from './host';
import {
    filterData
} from '@/assets/js/utils'
import url from './url'

import {getUrlParam} from '@/assets/js/utils';


window.APPID = 'wx8084a961292f4caf'

let mockUrl = host;

// axios.defaults.withCredentials = true // 跨域带sension
axios.defaults.headers.common["token"] = localStorage.wq_access_token;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.transformRequest = function (data) {
    if (data instanceof FormData) {
        return data
    }
    return querystring.stringify(data)
}

axios.interceptors.response.use(response => {
    let data = response.data;
    if (data == "login timeout!"){
        return {
            result: 200,
            message: '登录超时，请重新登录！'
        }
    }else{
        return typeof data === 'object' ? data : JSON.parse(data)
    }
}, error => {
    return Promise.reject(error.response || error) // 返回接口返回的错误信息
})

export default function (Vue, {
    router,
    store
}) {
    if (!Vue.prototype.$toast) {
        console.error('You have to use vant')
        return
    }
    const debug = process.env.NODE_ENV !== 'production'

    let info = (log, ...arg) => {
        debug && console.log(log, ...arg)
    }

    let getCode = ()=> { // 非静默授权，第一次有弹框
        const code = getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
        const local = window.location.href;
        console.log('code', code);
        console.log('local', local);

        if (code == null || code === '') {
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + window.APPID + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
        } else {
            getOpenId(code) //把code传给后台获取用户信息
        }
    }
    let getOpenId = (code)=> { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
        let param = {
            code
        }
        window.$api.wx_login({param,method:'post'}, res=>{
            console.log('login', res);
            if(!localStorage.openid || localStorage.openid == 'undefined' || localStorage.openid == 'null'){
                localStorage.openid = res.openid;
            }
            if(res.status == '40163' || res.status == '401'){
                router.push({path: '/token', query: {code, openid: res.openid}});
            }else if(res.status == '200'){
                localStorage.wq_access_token = res.wq_access_token;
                location.reload();
            }else{
                Vue.prototype.$dialog.alert({
                  message: '请加管理员扣扣：122267011  ，马上处理',
                }).then(() => {
                  // on close
                });
            }
        });
    }

    // 统一处理ajax数据返回
    const handleSuccess = (data, success, failure,config) => {
        // if (data.result == '100') {
            // let res = data.data || data.resultParm
            let res = data || data.resultParm;
            if(res['状态'] == '无权限'){
                console.log('123')
                wx.miniProgram.navigateTo({url: '/pages/buyPage/index'});
                // router.push({path:'/login'})
                // if(process.env.NODE_ENV == 'production'){
                    if(config.url.indexOf('user_info_query1')>-1 || config.url.indexOf('user_info_write1')>-1 || config.url.indexOf('train')>-1 || config.url.indexOf('jindu2_write')>-1 || config.url.indexOf('timer_write')>-1 || config.url.indexOf('user_info_query')>-1){

                    }else{
                        // getCode();
                    }
                // }
            }
            // info(res);
            success && success(res);
        // } else {
        //     failure && failure(data)
        //     console.warn(data)
        //     _error(data)
        // }
    }

    const _error = err => {
        Vue.prototype.$toast({
            type: 'text',
            message: err && err.message
        })
        console.error(err)
    }

    // 处理ajax最后的异常
    const handleFail = function (err, cb) {
        if (err.status == '404') {
            Vue.prototype.$toast({
                title: '404',
                message: 'uri地址不存在'
            })
        } else if (err.status == '407') {
            Vue.prototype.$toast({
                title: '公告',
                message: '系统维护中',
                showConfirmButton: false,
                closeOnClickModal: false
            })
        } else if (err.status == '413') {
            Vue.prototype.$toast({
                title: '413',
                message: '超出服务器上传限制',
                showConfirmButton: false,
                closeOnClickModal: false
            })
        } else {
            Vue.prototype.$toast({
                title: '提示',
                message: "网络异常" + (err.status ? '：' + err.status : '')
            })
        }
        cb && cb(err)
    }

    const ajax = (config, success, fail, isLoading) => {
        filterData(config.params)
        filterData(config.data)
        let loading = null,
            isLoadingFun = Object.prototype.toString.call(isLoading) === '[object Function]'
        if (fail && isLoading && !isLoadingFun) {
            // loading = Vue.prototype.$toast.loading({
            //     mask: true,
            //     message: '加载中...'
            // })
        }
        isLoadingFun && isLoading(true)
        return new Promise((resolve, reject) => {
            axios(config).then(res => {
                let failure = fail || _error
                loading && loading.clear();
                isLoadingFun && isLoading(false)
                handleSuccess(res, success, failure,config)
                resolve(res)
            }, err => {
                loading && loading.clear();
                isLoadingFun && isLoading(false)
                handleFail(err)
                reject(err)
            })
        })
    }


    const handleApi = function () {
        let api = {}
        for (let key in url) {
            if (url.hasOwnProperty(key)) {
                api[key] = ({
                    param,
                    method = 'get',
                    ...res
                }, success, failure = _error, isLoading = true) => {
                    let config = {
                        url: mockUrl + url[key],
                        method: method,
                        ...res
                    }
                    if (method == 'get') {
                        config.params = param
                    } else {
                        config.data = param
                    }
                    return ajax(config, success, failure, isLoading)
                }
            }
        }
        return api
    }

    function getError(option, xhr) {
        let msg, {
            url,
            method
        } = option
        if (xhr.response) {
            msg = `${xhr.response.error || xhr.response}`;
        } else if (xhr.responseText) {
            msg = `${xhr.responseText}`;
        } else {
            msg = `fail to ${method} ${url} ${xhr.status}`;
        }
        const err = new Error(msg);
        err.status = xhr.status;
        err.method = method;
        err.url = url;
        return err;
    }

    function getBody(xhr) {
        const text = xhr.responseText || xhr.response;
        if (!text) {
            return text;
        }
        try {
            return JSON.parse(text);
        } catch (e) {
            return text;
        }
    }

    router.beforeEach((to, from, next) => {
        /* 路由发生变化修改页面title */
        if (to.meta.name) {
            document.title = to.meta.name
        }
        next()
    })

    Vue.prototype.$api = window.$api = handleApi()
    Vue.prototype.$ajax = ({
        error = _error,
        success = () => {},
        data = {},
        ...option
    } = {}) => {
        let xhr = new XMLHttpRequest();
        option.url = mockUrl + option.url;
        let {
            type,
            url,
            async
        } = option
        let method = type || 'GET'
        xhr.onerror = e => {
            handleFail(e);
        };
        xhr.onload = () => {
            if (xhr.status < 200 || xhr.status >= 300) {
                return handleFail(getBody(xhr), error)
            }
            handleSuccess(getBody(xhr), success, error)
        };

        let params = querystring.stringify(data),
            param = null

        if (method.toLocaleUpperCase() === 'POST') {
            param = params
        } else {
            url = url + "?" + params
        }

        xhr.open(method.toLocaleUpperCase(), url, async ===false ? false : true)

        if (option.withCredentials && 'withCredentials' in xhr) {
            xhr.withCredentials = true;
        }
        const headers = option.headers || {};
        headers.token = localStorage.wq_access_token;
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        for (let item in headers) {
            if (headers.hasOwnProperty(item) && headers[item] !== null) {
                xhr.setRequestHeader(item, headers[item]);
            }
        }

        xhr.send(param)

        return xhr
    }
    Vue.prototype.$http = axios
}
