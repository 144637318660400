<!--
 * @Author: jhy
 * @Date: 2023-05-22 21:09:41
 * @LastEditTime: 2023-05-27 09:56:38
 * @LastEditors: jhy
 * @Description: 
 * @FilePath: /myProject/math-app/src/components/pay.vue
-->
<template>
    <div>
        <!-- 底部弹出 -->
        <van-popup
            v-model:show="showBottom"
            position="bottom"
            :style="{ height: '487px;' }"
        >
            <div class="prop">
                <div class="prop-title">
                    <span class="sp1">购买</span>
                    <span class="sp2">购买单教材后升级套餐可联系客服退差价</span>
                </div>
                <div class="list list1">
                    <div class="title">教材</div>
                    <div class="list-con">
                        <div class="item" @click="checkEvent(1)" :class="active==1?'on':''">
                            <div class="img">
                                <img src="@/assets/new_img/img15.png" alt="">
                            </div>
                            <div class="txt1">高数全册</div>
                        </div>
                        <div class="item" @click="checkEvent(2)" :class="active==2?'on':''">
                            <div class="img">
                                <img src="@/assets/new_img/img16.png" alt="">
                            </div>
                            <div class="txt1">线代全册加无限版</div>
                        </div>
                        <div class="item" @click="checkEvent(3)" :class="active==3?'on':''">
                            <div class="img">
                                <img src="@/assets/new_img/img17.png" alt="">
                            </div>
                            <div class="txt1">概率全册</div>
                        </div>
                        <div class="item" @click="checkEvent(4)" :class="active==4?'on':''">
                            <div class="img">
                                <img src="@/assets/new_img/img18.png" alt="">
                            </div>
                            <div class="txt1">十年真题加组题工具</div>
                        </div>
                    </div>
                </div>
                <div class="list list2">
                    <div class="title">活动</div>
                    <div class="list-con">
                        <div class="item" @click="checkEvent(5)" :class="active==5?'on':''">
                            <div class="img">
                                <img src="@/assets/new_img/img19.png" alt="">
                            </div>
                            <div class="txt2">核心100题系统公测</div>
                        </div>
                        <div class="item" @click="checkEvent(6)" :class="active==6?'on':''">
                            <div class="img">
                                <img src="@/assets/new_img/img20.png" alt="">
                            </div>
                            <div class="txt2">全套活动价</div>
                        </div>
                    </div>
                </div>

                <div class="btn" @click="buyEvent">
                    <span class="sp1">立即购买</span>
                    <span class="sp2">￥24</span>
                </div>

            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showBottom: false,
                active: 1,
            }
        },
        methods: {
            checkEvent(val){
                this.active = val;
            },
            buyEvent() {
                let openId = null;
                this.$toast('123')
                this.$toast('wx.login'+JSON.stringify(wx));
                wx.miniProgram.postMessage({
                    data: {
                        value: '1231232321312'
                    },
                })
                wx.miniProgram.navigateBack()
                wx.login({
                    success (res) {
                        if (res.code) {
                            this.$toast('res.code' + res.code)
                            let payer = res.code;
                            openId = payer;
                            let param = {
                                description: 'xi',
                                amount: 1,
                                payer
                            }
                            this.$api.pay_miniprog({param}).then(res=>{
                                console.log('res', res);
                                this.$toast('pay_miniprog' + res);
                                let { timeStamp, nonceStr, signType, paySign } = res.result
                                wx.requestPayment({
                                    timeStamp,
                                    nonceStr,
                                    package:res.result.package,
                                    signType,
                                    paySign,
                                    success(res){
                                        console.log(res);
                                    },
                                    fail(err){
                                        console.error(err);
                                    }
                                })
                            });
                        } else {
                            console.log('登录失败！' + res.errMsg)
                        }
                    }
                });
            }
        },
    }
</script>

<style lang="less" scoped>
.prop{
    padding: 20px;
    text-align: left;
    &-title{
        display: flex;
        .sp1{
            font-family: 'Source Sans 3';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            /* identical to box height */
            color: #2A3155;
        }
        .sp2{
            font-family: 'Source Sans 3';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 26px;
            /* identical to box height */
            color: rgba(42, 49, 85, 0.3);
            margin-left: 8px;
        }
    }
    .list{
        &-title{
            padding-top: 20px;
            /* 教材 */
            font-family: 'Source Sans 3';
            font-style: normal;
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height */

            color: rgba(42, 49, 85, 0.7);
        }
        &-con{
            display: flex;

            .item{
                width: 25%;
                &.on{
                    .img{
                        border: 2px solid #F9C200;
                        border-radius: 5px;
                        position: relative;
                        width: 72px;
                        height: 72px;
                        background: url('./../assets/new_img/Vector.png') no-repeat bottom right;
                    }
                }
                .txt1{
                    width: 72px;
                    line-height: 20px;
                    text-align: center;
                }
                .txt2{
                    font-family: 'Source Sans 3';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height */
                    text-align: center;

                    color: rgba(42, 49, 85, 0.5);
                }
            }
        }
        &.list2{
            padding-bottom: 35px;
            .item{
                width: 50%;
                img{
                    width: 159px;
                    height: 104px;
                }
                &.on{
                    .img{
                        border: 2px solid #F9C200;
                        border-radius: 5px;
                        position: relative;
                        width: 159px;
                        height: 104px;
                        background: url('./../assets/new_img/Vector.png') no-repeat bottom right;
                        &::before{
                            content: '';
                            width: 72px;
                            height: 72px;
                            background: url('./../assets/new_img/Vector.png') no-repeat bottom right;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
                .txt2{
                    padding-top: 10px;
                    width: 100%;
                }
            }
        }
    }
    .btn{
        width: 335px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        display: flex;
        background: #415FD5;
        border-radius: 24px;
        justify-content: center;

        .sp1{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;

            color: #FFFFFF;
        }
        .sp2{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            /* identical to box height */
            color: #FFFFFF;
        }
    }
}
</style>