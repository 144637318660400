<template>
  <div class="header">
    <img @click="goPath('/new_menu')" src="@/assets/img/home/header/logo.png" alt="logo">

    <div class="btn" @click="goPath('/new_result')" v-if="pathname=='/new_topic' || headerStauts">
      <img src="@/assets/new_img/img14.png" alt="" />
      <p>我的</p>
    </div>
    <div class="btn" @click="goPath('/favorites')" v-else>
      <img src="@/assets/img/home/header/collectedicon.png" alt="" />
      <p>我的</p>
    </div>
  </div>
</template>

<script>
import { getUrlParam, isWeiXin } from '@/assets/js/utils';

export default {
  data () {
    return {
      isLogin: '', //是否登录
    }
  },
  computed: {
    pathname() {
      return location.pathname
    },
    headerStauts() {
      return this.$store.state.headStatus;
    }
  },
  created () {
    this.isLogin = localStorage.access_token;
    if (!isWeiXin() && process.env.NODE_ENV !== 'development' && this.$route.name !== 'main') {
      this.getCode()
    }
  },
  methods: {
    goPath(path) {
      if(path=='/new_menu'){
        wx.miniProgram.navigateTo({url: '/pages/home/index'});
      }else{
        this.$router.push(path)
      }
    },
    getCode () { // 非静默授权，第一次有弹框
      const code = getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      const local = window.location.href;
      console.log('code', code);
      console.log('local', local);

      if (code == null || code === '') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + window.APPID + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
      } else {
        this.getOpenId(code) //把code传给后台获取用户信息
      }
    },
    getOpenId (code) { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
      let param = {
        code
      }
      this.$api.wx_login({param,method:'post'},res=>{
        console.log('login', res);
        if(res.message == '该用户不存在'){
          this.$router.push({path: '/token', query: {code}})
        }
      });
    }
  },
}
</script>

<style lang="less" scoped>
.header {
  height: 60px;
  padding: 0px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #415FD5;
  > img {
    width: 106px;
    // height: 29px;
  }

  .btn {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    padding: 4px 16px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.5);

    > img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    > p {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
    }
  }
}
</style>
