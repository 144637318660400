export const objDeepCopy = function (source) {
  var sourceCopy = {};
  for (var item in source) sourceCopy[ item ] = typeof source[ item ] === 'object' ? objDeepCopy(source[ item ]): source[ item ];
  return sourceCopy;
}

export const deepCopy = function (source) {
  var sourceCopy = source instanceof Array ? []: {};
  for (var item in source) {
    sourceCopy[ item ] = typeof source[ item ] === 'object' ? objDeepCopy(source[ item ]): source[ item ];
  }
  return sourceCopy;
}

/**
 * 过滤空值的属性
 * @param obj
 * @constructor
 */
export const filterData = function (obj) {
  for (var key in obj) {
    if (obj[ key ] == null || obj[ key ] === '') {
      delete obj[ key ]
    }
  }
}


export const formatDate = function (format, date = new Date()) {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "H+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[ k ]): (("00" + o[ k ]).substr(("" + o[ k ]).length)));
    }
  }
  return format;
}

export const on = (() => {
  if (document.addEventListener) {
    return (element, event, handler) => {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    }
  } else {
    return (element, event, handler) => {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler)
      }
    }
  }
})()
export const off = (() => {
  if (document.removeEventListener) {
    return (element, event, handler) => {
      if (element && event) {
        element.removeEventListener(event, handler, false)
      }
    }
  } else {
    return (element, event, handler) => {
      if (element && event) {
        element.detachEvent('on' + event, handler)
      }
    }
  }
})()

export const formatTime = function (num) {
  if (num > - 1 && num < 10) {
    return "0" + num + ':00';
  } else if (num > 9 && num < 24) {
    return num + ':00';
  }
}
// 获取cookie
export const getCookiedecode = function (key) {
  let cookiearr = decodeURIComponent(document.cookie).split('; ');
  for (var i = 0; i < cookiearr.length; i ++) {
    let needcookie = cookiearr[ i ].split('=');
    if (needcookie[ 0 ] == key) {
      return needcookie[ 1 ];
    }
    ;
  }
  ;
  return false;
}
// 设置cookie
export const setCookie = function (name, value) {
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie = name + "=" + encodeURIComponent(value) + ";expires=" + exp.toGMTString();
}

// 获取导航
export const getNavigate = function (name, value) {
  let navList = this.$store.state.navList;
  if (!navList) {
    console.log(111)
    this.$store.commit('getNavList');
    navList = this.$store.state.navList;
  }
  return navList;
}

// 获取专题导航
export const getNavList = function () {

}

export const getUrlParam = function (name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[ 2 ]);
  return null;
}

export const isWeiXin = function () {
  let ua = window.navigator.userAgent;
  if (ua.match(/MicroMessenger/i) == 'MicroMessenger') {
    return true;
  } else {
    return false;
  }
}

export function convertCanvasToImage (canvas) {
  var image = new Image();
  image.src = canvas.toDataURL("image/png");
  return image;
}

export function getTime () {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const hour = d.getHours();
  const minute = d.getMinutes();
  const second = d.getSeconds();
  return {
    year: parseInt(year),
    month: parseInt(month),
    day: parseInt(day),
    hour: parseInt(hour),
    minute: parseInt(minute),
    second: parseInt(second)
  }
}

// 是否是微信
export function isWeChat () {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  }
  return false
}

export function numToChinese(num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    alert("Number is wrong!");
    return "Number is wrong!";
  }
  var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
  var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
  var a = ("" + num).replace(/(^0*)/g, "").split("."),
    k = 0,
    re = "";
  for (var i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re;
        break;
      case 4:
        if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
          re = BB[4] + re;
        break;
      case 8:
        re = BB[5] + re;
        BB[7] = BB[5];
        k = 0;
        break;
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
    if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
    k++;
  }
  if (a.length > 1) //加上小数部分(如果有小数部分)
  {
    re += BB[6];
    for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
  }
  return re;
};
