import sha1 from 'sha1';

export default {
  metaInfo: {
    title: '考研数学：暑期阶段自测',
    meta: [
      { name: 'description', content: '暑期阶段测试，预测分数&全网排名&能力模型&深度思路讲解' },
      { itemprop: 'image', content: 'http://math.jinghanyu.xyz/WechatIMG691.jpeg'  }
    ]
  },
  data () {
    return {
      sign: '',
      noncestr: 'Wm3WZYTPz0wzccnW',
      timestamp: 0
    }
  },
  methods: {
    async getAccessToken() {
      try {
        const ret = await this.$api.wx_ticket({
          param: {
            acc_code: 'wuqiong2020'
          }
        });
        this.timestamp = parseInt(new Date().getTime()/1000)
        const str = `jsapi_ticket=${ret.ticket}&noncestr=${this.noncestr}&timestamp=${this.timestamp}&url=${location.href}`
        this.sign = sha1(str);
        this.initWxSdk()
      } catch (err) {
        console.error(err)
      }
    },

    initWxSdk () {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: window.APPID, // 必填，公众号的唯一标识
        timestamp: this.timestamp, // 必填，生成签名的时间戳
        nonceStr: this.noncestr, // 必填，生成签名的随机串
        signature: this.sign,// 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
      });
    },

    initShare () {
      console.log(location)

      wx.ready(function () {   // 需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: '暑期阶段测试，看看你的考研数学能得几分！', // 分享标题
          desc: '测试结果是110分以上的“霸霸”请联系我', // 分享描述
          link: `${location.origin}/activity`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'http://math.jinghanyu.xyz/WechatIMG691.jpeg', // 分享图标
          success: function () {
            console.log('updateAppMessageShareData 初始化成功')
          }
        })
        wx.updateTimelineShareData({
          title: '暑期阶段测试，看看你的考研数学能得几分！', // 分享标题
          desc: '测试结果是110分以上的“霸霸”请联系我', // 分享描述
          link: `${location.origin}/activity`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'http://math.jinghanyu.xyz/WechatIMG691.jpeg', // 分享图标
          success: function () {
            console.log('updateTimelineShareData 初始化成功')
          }
        })
      });
    }
  }
}
