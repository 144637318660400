<template>
    <div id="app">
        <Header v-if="hiddenHeader" />
        <router-view :key="$router.currentRoute.fullPath" />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import {getUrlParam,isWeiXin} from '@/assets/js/utils';
    import wxSDKMixins from "@/views/activity/mixins";
    import shareMixin from '@/mixins/shareMixin';
    export default {
      mixins: [wxSDKMixins, shareMixin],
        components: {
            Header,
        },
        computed: {
            hashName() {
                let hashName = this.$store.state.hashName;
                console.log('hashName',hashName);
                return hashName;
            },

            // 隐藏顶部头
            hiddenHeader () {
              const blackHashNames = [
                  'Home',
                  'activity', // 活动首页
                  'activity-topic', // 活动倒计时页
                  'activity-topic-list',
                  'activity-result', // 活动结果页
                  'refactor-question', // 重组页
                  'new_home', // 新首页
                  'new_menu', 
                  'new_result',
              ]
              return !blackHashNames.includes(this.hashName)
            }
        },
        created () {
            // 测试环境
            // localStorage.access_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ4dW5saWFudGkiLCJpYXQiOjE2NDcwODA3NjgsInVzZXJuYW1lIjoib2QtaGY2TFJQZXItY3RNR3JzcTVoTW43S2tQZyIsInNjb3BlcyI6WyJvcGVuIl19.-TnSI8Mw4wS96LBccgKWr29zSy_Pt_RA3z-VwF3qu4A';
            // localStorage.openid = 'od-hf6LRPer-ctMGrsq5hMn7KkPg';
            window.APPID = 'wx8084a961292f4caf'

            console.log('process.env.ENV', process.env.NODE_ENV)
            let token = localStorage.getItem('wq_access_token');
            // this.$dialog({message: token})
            if(token && token!=='undefined'){
                return
            }
            let query = this.deal_with_locationsearch();
            query.code && this.getOpenId(query.code);
            console.log('this.$route.query', this.$route.query);
            console.log('search', location.search);
            
        },
        methods: {
            async getOpenId (code) {
                // this.$toast('code-'+code+'-'+JSON.stringify(wx));
                let param = {
                    code
                }
                this.$api.wx_login({param,method:'post'}).then(res=>{
                    console.log('login', res);
                    // this.$dialog({message: code+'   res:'+JSON.stringify(res)})
                    // this.$toast('wq_access_token'+res.wq_access_token);
                    localStorage.setItem('wq_access_token', res.wq_access_token)
                    if(localStorage.getItem('wq_access_token') && localStorage.getItem('wq_access_token') !=='undefined'){
                        location.reload();
                    }
                });
            },
            deal_with_locationsearch(){
                var search = location.search.substr(1).split('&'); //初步处理数据-->去掉?分开多个键值对

                var obj = {};
                //创建对象

                function deal_with_key_value(obj, key_and_value) {
                    for (var i = 0; i < key_and_value.length; i++) {
                        var pos = key_and_value[i].split('=');
                        obj[pos[0]] = pos[1];
                            
                    } //遍历location.search中每一个元素
                }
                deal_with_key_value(obj, search); //调用

                return obj; //最终返回值为对象
            }
        }
    }
</script>

<style lang="less">
#app {
    font-family: '宋体',Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #fff;
    min-height: 100vh;

    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
}
#app::-webkit-scrollbar {
  display: none;
}

@import "https://at.alicdn.com/t/font_2124008_rmb1gxqe0qh.css";
</style>
