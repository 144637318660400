import sha1 from 'sha1';

export default {
  metaInfo: {
    title: '无穹解题',
    meta: [
      { name: 'description', content: '利用提示、分步解析和总结不断刺激解题思路形成&高等数学&真题重组&实战真题' },
      { itemprop: 'image', content: 'http://math.jinghanyu.xyz/WechatIMG691.jpeg'  }
    ]
  },
  data () {
    return {
      sign: '',
      noncestr: 'Wm3WZYTPz0wzccnW',
      timestamp: 0
    }
  },
  methods: {
    initShare () {
      // wx.ready(function () {   // 需在用户可能点击分享按钮前就先调用
      //   wx.updateAppMessageShareData({
      //     title: '考研数学思路养成利器！', // 分享标题
      //     desc: '利用提示、分步解析和总结不断刺激解题思路形成！', // 分享描述
      //     link: `${location.origin}/`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //     imgUrl: 'http://math.jinghanyu.xyz/WechatIMG691.jpeg', // 分享图标
      //     success: function () {
      //       console.log('updateAppMessageShareData 初始化成功')
      //     }
      //   })
      //   wx.updateTimelineShareData({
      //     title: '考研数学思路养成利器！', // 分享标题
      //     desc: '利用提示、分步解析和总结不断刺激解题思路形成！', // 分享描述
      //     link: `${location.origin}/`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //     imgUrl: 'http://math.jinghanyu.xyz/WechatIMG691.jpeg', // 分享图标
      //     success: function () {
      //       console.log('updateTimelineShareData 初始化成功')
      //     }
      //   })
      // });
    }
  }
}
