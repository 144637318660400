/*
 * @Author: jhy
 * @Date: 2022-05-01 10:58:41
 * @LastEditTime: 2023-06-06 20:59:29
 * @LastEditors: jhy
 * @Description: 
 * @FilePath: /math-app/src/api/url.js
 */
module.exports = {
    test: '/test-demo', //test
    wx_ticket: '/get_wx_js_ticket',

    login: '/login',
    wx_login: '/wx_login_mini',
    register: '/register',
    train_left: '/train_left',// 菜单导航
    navigate: '/navigate',
    xiandai: '/navigate/xiandai',
    xiandai_standard: '/navigate/xiandai_standard',
    gailv: '/navigate/gailv',
    gaoshushang: '/navigate/gaoshushang',

    zhenti_navigate: '/zhenti_navigate',

    gongshifajie: '/train/gongshifajie',//test
    mat: '/introduce/mat',

    achieve_query: '/achieve_query',//   获取做题信息
    stat_write: '/stat_write',//  上传做题信息
    jindu_query: '/jindu_query', // 获取当前做题情况
    jindu_write: '/jindu_write', // 写入当前做题情况

    jindu2_query: '/jindu2_query', // 获取当前做题情况
    jindu2_write: '/jindu2_write', // 写入当前做题情况

    user_info_write: '/user_info_write1', // 进度写入
    user_info_query: '/user_info_query1', // 进度读取

    zongjie_write: '/zongjie_write', // 总结写入
    zongjie_query: '/zongjie_query', // 总结查询
    zongjie_query_agg: '/zongjie_query_agg',

    timer_write: '/timer_write',
    timer_query: '/timer_query',

    schedule_query: '/schedule_query',
    schedule_write: '/schedule_write',
    book_reset: '/book_reset',

    label_write:'/label_write', // 收藏
    shoucang_query_agg: '/shoucang_query_agg', //

    activityQuestions: '/get_exams', // 获取测试活动题目
    activitySubmitAnswer: '/get_score', // 提交回答
    activityGetResult: '/get_user_score', // 获取提交结果

    queryRealQuestionDirectory: '/year_catalogue', // 查询真题目录

    createRealRefactor: '/zujuan_catalog', // 真题组卷
    queryRefactorDirectory: '/zujuan_recall', // 查询组卷目录

    queryRefactorTopicDirectory: '/big_catalogue', // 查询专题组卷目录

    queryQuestion: '/exam', // 题目查询
    resultSave: '/catalog_config_save', // 做题结果同步
    queryQuestionResult: '/catalog_config_query', // 做题结果获取

    pushCollection: '/favorite_save', // 收藏
    cancelCollection: '/favorite_del', // 取消收藏
    queryRealCollection: '/favorite_agg', // 查询真题收藏项
    queryAdvanceCollection: '/shoucang_query_agg', // 查询高数收藏项
    delAdvanceCollection: '/shoucang_del', // 删除高数收藏
    queryCollectionDirectory: '/favorite_catalog', // 收藏目录

    queryCollectBookTab: '/shoucang_book_query',

    relate_exam: '/relate_exam', // relate_exam

    free: '/free',

    write_mathtype: '/write_mathtype',
    tuijian: '/tuijian',
    zsd_stat_write: '/zsd_stat_write',
    zsd_trace_write: '/zsd_trace_write',
    zsd_del: '/zsd_del', // 重置接口

    pay_miniprog: '/api/pay_miniprog', // 获取购买参数
}
