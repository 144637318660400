/*
 * @Author: jhy
 * @Date: 2022-03-17 09:56:15
 * @LastEditTime: 2022-03-17 09:58:15
 * @LastEditors: jhy
 * @Description:
 * @FilePath: /math-app/src/api/host.js
 */

// export default 'https://wqkaoyan.xyz/api'
export default 'https://wqkaoyan.xyz/api'
